import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OrderInfo} from '@service-and-repairs/dms-api';
import {ServiceCase} from '../../../../core/service-case/models/service-case';
import {UserService} from '../../../../core/user/services/user.service';

@Component({
  selector: 'app-dms-order-for-vin-selection',
  templateUrl: './dms-order-for-vin-selection.component.html',
  styleUrls: ['./shared-styles.scss']
})
export class DmsOrderForVinSelectionComponent {
  @Output()
  newCaseWithOrder = new EventEmitter<ServiceCase>();

  @Input()
  orderList: OrderInfo[];

  visible = false;

  constructor(private userService: UserService) {
  }

  orderSelected(dcomOrder: OrderInfo): void {
    this.visible = false;
    const newServiceCaseWithDmsData = ServiceCase.constructMinimalServiceCase(this.userService.userSubject.getValue());
    if (dcomOrder.vehicle.vin?.length === 7) {
      newServiceCaseWithDmsData.setVinShort(dcomOrder.vehicle.vin);
    } else {
      newServiceCaseWithDmsData.setVinLong(dcomOrder.vehicle.vin);
    }
    newServiceCaseWithDmsData.setLicensePlate(dcomOrder.vehicle.numberPlate);
    newServiceCaseWithDmsData.setDmsOrderNumber(dcomOrder.serviceOrderNumber);
    newServiceCaseWithDmsData.setDmsCustomerNumber(dcomOrder.customer?.customerId?.dmsId);
    this.newCaseWithOrder.emit(newServiceCaseWithDmsData);
  }

}
