import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {environment} from '@environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationTokenBearerService} from '../auth/authentication-token-bearer.service';
import {OidcToken} from '../auth/oidc/oidc-token';
import {NavigationService} from './navigation.service';
import {NotificationsService} from './notifications.service';
import {AirParamService} from '../integration/air-param.service';
import {ThinClientModeService} from './thin-client-mode.service';
import {ClientUpdateRequest, UpdateDownloadInfo, UpdateVersionInfo, UpdateVersionResponse} from './thin-client.payload';

export const TERMINAL_SERVER_SESSION_STORAGE_FLAG = 'terminalServerMode';

@Injectable({
  providedIn: 'root'
})
export class ThinClientService {

  currentVersion: string;
  terminalServerInstallation = false;

  constructor(private httpClient: HttpClient,
              private navigationService: NavigationService,
              private airParamService: AirParamService,
              private authenticationTokenBearerService: AuthenticationTokenBearerService,
              private zone: NgZone,
              private notificationsService: NotificationsService,
              private translate: TranslateService,
              private route: ActivatedRoute) {
    ThinClientModeService.thinClientMode = navigator.userAgent.indexOf('AwpThinClient') >= 0;
    console.log('[Webapp] [ThinClientService] running in thin client: ' + ThinClientModeService.thinClientMode);
  }

  private static isNewerVersionAvailable(currentVersion: string, latestVersion: string): boolean {
    if (latestVersion !== undefined) {
      const currentVersionParts = currentVersion.split('.');
      const latestVersionParts = latestVersion.split('.');
      const minLength = currentVersion.length > latestVersion.length ? latestVersion.length : currentVersion.length;
      for (let i = 0; i < minLength; ++i) {
        if (Number(currentVersionParts[i]) < Number(latestVersionParts[i])) {
          return true;
        }
      }
    }
    return false;
  }

  initialize(isInternet: boolean): void {
    if (ThinClientModeService.thinClientMode) {
      this.registerAirForkCallback();
      this.registerNavigateByUriSchemeCallback();
      this.initializeUpdateLogConfig();
      this.hasBeenCalledByApas();
      this.hasBeenCalledFromTerminalServer();
      this.notifyReady();
      this.checkForUpdate(isInternet);
    }
  }

  initializeUpdateLogConfig(): void {
    // subscribe also immediately calls the method
    this.authenticationTokenBearerService.oidcTokenSubject.subscribe(oidcToken => this.updateLogConfig(oidcToken));
  }

  /**
   * Updates the ThinClient remote logging configuration: tells the ThinClient the CaVORS sessions ID
   * as well as information where and how to send its .NET client logs to the logcollector service.
   */
  private updateLogConfig(oidcToken: OidcToken) {
    const cavorsSessionId = sessionStorage.getItem('cavors-session-id');
    const url = window.location.origin + '/api/logcollector/logs';
    const authHeaders = oidcToken.getHeaders();
    const putBody = {
      cavorsSessionId,
      url,
      authHeaders
    };
    this.httpClient.put('/api/client/logs/config', putBody).pipe(
      catchError(error => of(error))
    ).subscribe();
  }

  checkForUpdate(isInternet: boolean): void {
    this.getCurrentVersion().subscribe({
      next: (currentVersion: any) => {
        this.currentVersion = currentVersion;
        this.getLatestVersion(isInternet).subscribe({
          next: (availableVersions: UpdateVersionResponse) => {
            let versions: UpdateVersionInfo = this.terminalServerInstallation
              ? availableVersions.terminalServer
              : availableVersions.standard;
            this.evaluateUpdate(currentVersion, versions);
          },
          error: (error) => console.log('Can not get latest version of AWP Thin client', error)
        });
      },
      error: (error) => console.log('Can not get current version of AWP Thin client', error)
    });
  }

  private notifyReady(): void {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = {ready: true};
    this.httpClient.put('/api/client/status/frontend', body, {headers}).pipe(
      catchError(error => of(error))
    ).subscribe();
  }

  private registerAirForkCallback(): void {
    (window as any).openAirWithParameters = (queryParams: any) => {
      this.zone.run(() => {
        this.airParamService.addMandatoryAirParams(queryParams);
        const params = {
          pathParam: 'fork',
          queryParams
        };
        this.navigationService.navigateTo('air', {state: params});
      });
    };
  }

  /**
   * ThinClient installation will register the awp:/ protocol on the user's machine. This can be used to open the
   * client with arbitrary parameters. If the user opens e.g. 'awp:/start?app=vehicledetails&vin=WBAAC11080AB12345',
   * the ThinClient will call a function window.navigateByUriScheme with
   * 'start?app=vehicledetails&vin=WBAAC11080AB12345'.
   */
  private registerNavigateByUriSchemeCallback(): void {
    (window as any).navigateByUriScheme = (paramString: string) => {
      console.log(`window.navigateByUriScheme called with "${paramString}".`);

      this.zone.run(() => {
        this.navigationService.navigateTo(this.parseNavigateByUriSchemeParameterString(paramString));
      });
    };
  }

  /**
   * Parse the string passed to navigateByUriScheme to an AWP URL.
   * @param paramString The string set by the ThinClient, e.g."start?app=vehicledetails&vin=WBAAC11080AB12345".
   * @return The string in AWP format, e.g. /vehicledetails?vin=WBAAC11080AB12345.
   */
  private parseNavigateByUriSchemeParameterString(paramString: string): string {
    paramString = paramString.replace('/start?', '');
    const params = paramString.split('&');
    let app: string = '';
    params.forEach((param: string, index: number) => {
        if (param.indexOf('app') >= 0) {
          app = params[index].replace('app=', '');
          params.splice(index, 1);
        }
      }
    );
    const queryParamsWithoutApp = params.join('&');
    return app ? '/' + app + '?' + queryParamsWithoutApp : '?' + queryParamsWithoutApp;
  }

  private getCurrentVersion() {
    return this.httpClient.get('/api/client/version', {responseType: 'text'});
  }

  private getLatestVersion(isInternet: boolean) {
    const updateServiceUrl = isInternet
      ? environment.urls.updateService.b2d
      : environment.urls.updateService.b2dIntranet;
    return this.httpClient.get(updateServiceUrl);
  }

  /**
   * Triggers a ThinClient update if current version is older than the latest stable and if not running on a terminal
   * server. Will only show a notification in case of the latter.
   *
   * @param currentVersion current version of the thin client
   * @param availableVersions available versions of the thin client
   */
  private evaluateUpdate(currentVersion: string, availableVersions: UpdateVersionInfo): void {
    if (currentVersion !== undefined && availableVersions !== undefined) {
      const latestVersion = availableVersions.latestStable;
      if (ThinClientService.isNewerVersionAvailable(currentVersion, latestVersion)) {
        if (!this.terminalServerInstallation) {
          const updateData: UpdateDownloadInfo = availableVersions.versions[latestVersion];
          this.triggerUpdate(latestVersion, updateData.downloadUrl, updateData.requiresAuth);
        } else {
          this.notificationsService.showInfoNotification(
            this.translate.instant('thinClient.terminalServerUpdateAvailable'),
            0
          );
        }
      }
    }
  }

  private triggerUpdate(version: string, downloadUrl: string, requiresAuth: boolean) {
    const authHeaders = requiresAuth
      ? this.authenticationTokenBearerService.oidcTokenSubject.getValue().getHeaders()
      : {};
    const postBody: ClientUpdateRequest = {
      version,
      downloadUrl,
      authHeaders
    };
    this.httpClient.post('/api/client/update', postBody).pipe(
      catchError(error => of(error))
    ).subscribe(() => {
      this.notificationsService.showInfoNotification(
        this.translate.instant('thinClient.updateAfterRestart'),
        0
      );
    });
  }

  private hasBeenCalledByApas() {
    this.route.queryParams.subscribe((params) => {
      if (params?.mode === 'apas') {
        ThinClientModeService.apasMode = true;
      }
    });
  }

  private hasBeenCalledFromTerminalServer(): void {
    this.route.queryParams.subscribe((params) => {
      // Special terminal server startup parameter; see ISPI-291677
      if (params?.terminalServer) {
        this.terminalServerInstallation = true;

        // store terminal server flag to session storage, otherwise it would get lost on refresh
        sessionStorage.setItem(TERMINAL_SERVER_SESSION_STORAGE_FLAG, 'true');
      }
    });
    if (sessionStorage.getItem(TERMINAL_SERVER_SESSION_STORAGE_FLAG) === 'true') {
      this.terminalServerInstallation = true;
    }
  }
}
