import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {UserData} from '../../user/models/user-data';
import {UserService} from '../../user/services/user.service';
import {ServiceCaseLifecycle} from '../interfaces/service-case-lifecycle';
import {ServiceCase} from '../models/service-case';

@Injectable({
  providedIn: 'root'
})
export class ServiceCaseLifecycleManagerService {

  constructor(private httpClient: HttpClient,
              private userService: UserService) {
  }

  createServiceCaseCentral(serviceCase: ServiceCase): Promise<string> {
    const user: UserData = this.userService.userSubject.getValue();
    if (!user || user?.getContext() === 'B2E') {
      return Promise.resolve(undefined);
    }

    const baseUrl: string = user.getContext() === 'B2D'
      ? environment.urls.sccLifecycleManagementService.b2d
      : environment.urls.sccLifecycleManagementService.b2dIntranet;

    return new Promise<string>(resolve => {
      this.httpClient.post(
        baseUrl + '/api/v1/ingestion/service-cases/awp',
        serviceCase
      )
        .subscribe({
          next: (data: ServiceCaseLifecycle) => resolve(data.id),
          error: () => resolve(undefined)
        });
    });
  }

  updateServiceCaseCentral(serviceCase: ServiceCase): void {
    const user: UserData = this.userService.userSubject.getValue();
    if (!user || user?.getContext() === 'B2E') {
      return;
    }

    const baseUrl: string = user.getContext() === 'B2D'
      ? environment.urls.sccLifecycleManagementService.b2d
      : environment.urls.sccLifecycleManagementService.b2dIntranet;

    this.httpClient.patch(
      baseUrl + '/api/v1/service-cases/' + serviceCase.getSccId() + '/awp',
      serviceCase
    )
      .subscribe();
  }

  updateLastOpenServiceCaseCentral(serviceCase: ServiceCase): void {
    const user: UserData = this.userService.userSubject.getValue();
    if (!user || user?.getContext() === 'B2E') {
      return;
    }

    const baseUrl: string = user.getContext() === 'B2D'
      ? environment.urls.sccLifecycleManagementService.b2d
      : environment.urls.sccLifecycleManagementService.b2dIntranet;

    this.httpClient.patch(
      baseUrl + '/api/v1/service-cases/' + serviceCase.getSccId() + '/awp/' + serviceCase.getExternalId() + '/opened',
      null
    )
      .subscribe();
  }
}
