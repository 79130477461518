import {Injectable} from '@angular/core';
import {IPart, Part} from '@service-and-repairs/awpintegrationlib';
import {DmsService} from '../components/dms-search/dms.service';
import {CustomerService} from '../core/customer/services/customer.service';
import {MileageLoader} from '../core/mileage/services/mileage.loader';
import {ServiceCaseHolder} from '../core/service-case/models/service-case-holder';
import {ServiceCaseManager} from '../core/service-case/models/service-case-manager';
import {ServiceCaseUpdater} from '../core/service-case/models/service-case-updater';
import {IWebComponentAppConfiguration} from "../interfaces/IWebComponentAppConfiguration";
import {FlatRateUnitsAddEvent, PartsSearchPart, PartsSearchPartsAddEvent} from "./types";

/**
 * This service provides event listeners to react to web component app events (outputs).
 */
@Injectable({
  providedIn: 'root'
})
export class WebComponentEventService {
  constructor(private dmsService: DmsService,
              private serviceCaseHolder: ServiceCaseHolder,
              private serviceCaseUpdater: ServiceCaseUpdater,
              private serviceCaseManager: ServiceCaseManager,
              private customerService: CustomerService,
              private mileageLoader: MileageLoader) {
  }

  getEventListenersForApp(app: IWebComponentAppConfiguration): object {
    if (app.routingPath === 'flatratesearch') {
      return {
        addToList: (event: FlatRateUnitsAddEvent): void =>
          this.serviceCaseUpdater.addFlatRateUnitsFromFlatRateSearch(event.detail.flatrateGroups)
      };
    } else if (app.routingPath === 'servicedemand' || app.routingPath === 'serviceleads') {
      return {
        ready: (event: Event): void => {
          // set dmsApi on leads-cockpit-vehicle and leads-cockpit-fleet once it is ready
          event.target['api'].setDmsInstance?.(this.dmsService.dmsApi);

          // also set customer lib once it has loaded
          this.customerService.customerLibLoaded.then(customerLib =>
            event.target['api'].setCustomerLibInstance?.(customerLib));
        },
        requestMileageUpdate: (): void => {
          this.mileageLoader.loadMileage(this.serviceCaseHolder.getActiveCase()).finally();
        }
      };
    } else if (app.routingPath === 'partssearch') {
      return {
        addPartsEvent: (event: PartsSearchPartsAddEvent) => {
          const parts = [this.mapPartFromPartsSearch(event.detail.part)];
          event.detail.additionalParts.forEach(part => parts.push(this.mapPartFromPartsSearch(part)));
          this.serviceCaseUpdater.addPartsFromPartSearch(parts);
        }
      };
    } else if (app.routingPath === 'caseoverview') {
      return {
        metaDataUpdated: (event: CustomEvent) => {
          const serviceCaseId = event.detail;
          if (this.serviceCaseHolder.isServiceCaseOpen(serviceCaseId)) {
            this.serviceCaseManager.reloadServiceCaseById(serviceCaseId, false);
          }
        }
      };
    } else {
      return {};
    }
  }

  private mapPartFromPartsSearch(part: PartsSearchPart): IPart {
    return new Part(
      part.partNumber.slice(0, 2),
      part.partNumber.slice(2, 4),
      part.partNumber.slice(4),
      part.partDescription,
      part.quantity
    );
  }
}
