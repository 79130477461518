import {Injectable} from '@angular/core';
import {ServiceCase} from '../models/service-case';
import {ServiceCaseService} from './service-case.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceCaseLoader {
  constructor(private serviceCaseService: ServiceCaseService) {
  }

  createServiceCase(serviceCase: ServiceCase): Promise<ServiceCase> {
    return this.serviceCaseService.createServiceCase(serviceCase);
  }

  getServiceCaseById(externalId: string): Promise<ServiceCase> {
    return this.serviceCaseService.getServiceCase(externalId);
  }

  getServiceCasesByVin(vin: string): Promise<ServiceCase[]> {
    return this.serviceCaseService.getServiceCasesByVin(vin);
  }
}
