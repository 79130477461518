import type {IWebComponentAppConfiguration} from '../../interfaces/IWebComponentAppConfiguration';
import type {IWebComponentApp} from './IWebComponentApp';

export class WebComponentInstance {
  private webComponentElement: HTMLElement;

  constructor(private app: IWebComponentApp, private additionalAttributes: object) {
    this.createWebComponent();
  }

  /**
   * Check if an app is active (i.e. the user has not navigated away).
   * @param appConfiguration The configuration of the app to be checked.
   */
  static isAppActive(appConfiguration: IWebComponentAppConfiguration): boolean {
    return window.location.pathname.includes(appConfiguration.routingPath);
  }

  destroy(): void {
    this.webComponentElement.parentElement.removeChild(this.webComponentElement);
    this.webComponentElement = undefined;
  }

  /**
   * Set an attribute on the web component. Will only be set if the value differs from the existing value.
   * @param name The attribute name.
   * @param value The attribute value.
   */
  setAttribute(name: string, value: any): void {
    const jsonValue = typeof value === 'string' ? value : JSON.stringify(value);
    if (this.webComponentElement.getAttribute(name) !== jsonValue) {
      this.webComponentElement.setAttribute(name, jsonValue);
    }
  }

  /**
   * Show or hide the web component.
   * @param visible True if the app should be shown.
   */
  setVisibility(visible: boolean): void {
    this.webComponentElement.hidden = !visible;
    // workaround for ueg-awp-service-case-overview, which does not seem to accept the hidden attribute
    this.webComponentElement.style.display = visible ? 'block' : 'none';
  }

  getAttribute(name: string): string {
    return this.webComponentElement.getAttribute(name);
  }

  reload(): void {
    // copy key data and hidden attributes to new element
    const appHidden: boolean = this.webComponentElement.hidden;
    const keyDataReadDate: string = this.webComponentElement.getAttribute('key-data-read-date');

    this.destroy();
    this.createWebComponent();

    this.setAttribute('key-data-read-date', keyDataReadDate);
    if (!appHidden && WebComponentInstance.isAppActive(this.app.config)) {
      this.setVisibility(true);
    } else {
      this.setVisibility(false);
    }
  }

  private createWebComponent(): void {
    const parentElement = document.getElementsByClassName('awp-viewport').item(0);
    this.webComponentElement = this.app.targetDocument.createElement(this.app.config.webComponentCustomTag);
    this.webComponentElement.style.width = '100%';
    this.webComponentElement.style.display = 'none';
    this.webComponentElement.hidden = false;
    this.setAttributes();
    this.registerEventListeners();
    parentElement.appendChild(this.webComponentElement);
  }

  /**
   * Add event listeners to the web component.
   */
  private registerEventListeners(): void {
    const eventListeners = this.app.eventListeners;
    if (eventListeners) {
      Object.keys(eventListeners).forEach((key: string) => {
        if (typeof eventListeners[key] === 'function') {
          this.webComponentElement.addEventListener(key, eventListeners[key]);
        }
      });
    }
  }

  /**
   * Adds attributes to the web component: OIDC token, attributes configured in RouteFactoryService and
   * navigateTo parameters.
   */
  private setAttributes(): void {
    const attributes = {};

    // Attributes from RouteFactoryService
    if (typeof this.app.getAttributes === 'function') {
      Object.assign(attributes, this.app.getAttributes());
    }

    // additional attributes passed to the constructor
    Object.assign(attributes, this.additionalAttributes);

    // additional source loading errors
    this.app.sourceLoadingErrors?.forEach((errorIndicator: string) => attributes[errorIndicator] = true);

    // Set collected attributes on web component
    Object.keys(attributes).forEach((key: string) => this.setAttribute(key, attributes[key]));
  }
}
