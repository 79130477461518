import {formatDate} from '@angular/common';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActorId, IServerApi, IVehicle} from '@service-and-repairs/awpintegrationlib';
import {OrderInfo, OrderSearchResult, TransferMode} from '@service-and-repairs/dms-api';
import {Subject} from 'rxjs';
import {DmsVehicle} from '../../../components/dms-search/dms-vehicle';
import {DmsService} from '../../../components/dms-search/dms.service';
import {
  CaseSelectionOverlayComponent
} from '../../../components/service-case-bar/case/selection/case-selection-overlay.component';
import {
  VehicleSelectionOverlayComponent
} from '../../../components/vehicle/vehicle-selection-overlay/vehicle-selection-overlay.component';
import {AwpClientLibService} from '../../../services/awp-client-lib.service';
import {AnalyticsAction} from '../../analytics/enums/analytics-action';
import {VehicleSearchOrigin} from '../../analytics/enums/vehicle-search-origin';
import {AnalyticsService} from '../../analytics/services/analytics.service';
import {UserService} from '../../user/services/user.service';
import {VehicleDataLoader} from '../../vehicle/services/vehicle-data.loader';
import {VehicleLoader} from '../../vehicle/services/vehicle.loader';
import {ServiceCaseLoader} from '../services/service-case.loader';
import {ServiceCase} from './service-case';
import {ServiceCaseHolder} from './service-case-holder';
import {ServiceCaseUpdater} from './service-case-updater';

@Injectable({
  providedIn: 'root'
})
export class ServiceCaseManager {

  private awpLib: IServerApi;

  private caseSelectionOverlay: CaseSelectionOverlayComponent;

  private MAX_SEARCH_RESULTS = 10;

  caseLoadingStarted = new Subject<void>();

  caseLoadingFinished = new Subject<void>();

  constructor(awpClientLibService: AwpClientLibService,
              private analyticsService: AnalyticsService,
              private dmsService: DmsService,
              private serviceCaseHolder: ServiceCaseHolder,
              private serviceCaseLoader: ServiceCaseLoader,
              private serviceCaseUpdater: ServiceCaseUpdater,
              private translate: TranslateService,
              private userService: UserService,
              private vehicleLoader: VehicleLoader,
              private vehicleDataLoader: VehicleDataLoader) {
    this.awpLib = awpClientLibService.serverApi;

    this.translate.onLangChange.subscribe(this.onTranslationChange.bind(this));

    this.subscribeToRequestToOpenServiceCaseByVin();
  }

  setSelectionOverlays(vehicleSelectionOverlay: VehicleSelectionOverlayComponent,
                       caseSelectionOverlay: CaseSelectionOverlayComponent): void {
    this.vehicleLoader.vehicleSelectionOverlay = vehicleSelectionOverlay;
    this.caseSelectionOverlay = caseSelectionOverlay;
  }

  // region create cases

  openNewServiceCaseWithoutVehicle(): void {
    this.createAndOpenServiceCase(
      ServiceCase.constructMinimalServiceCase(this.userService.userSubject.getValue()),
      VehicleSearchOrigin.NO_VEHICLE
    );
  }

  openNewServiceCaseWithVehicle(vehicle: IVehicle): void {
    const serviceCase = ServiceCase.constructMinimalServiceCase(this.userService.userSubject.getValue());
    serviceCase.updateVehicle(vehicle);
    this.createAndOpenServiceCase(serviceCase, VehicleSearchOrigin.TYPE_ATTRIBUTES);
  }

  openNewServiceCaseByTypeCode(typeCode: string): void {
    this.caseLoadingStarted.next();
    const serviceCase = ServiceCase.constructMinimalServiceCase(this.userService.userSubject.getValue());
    serviceCase.setTypeCode(typeCode.toUpperCase());
    this.createAndOpenServiceCase(serviceCase, VehicleSearchOrigin.TYPE_CODE);
  }

  private openNewServiceCaseByVin(vin: string, origin: string): void {
    const serviceCase = ServiceCase.constructMinimalServiceCase(this.userService.userSubject.getValue());
    vin = vin.toUpperCase();
    serviceCase.setVinLong(vin.length === 17 ? vin : null);
    serviceCase.setVinShort(vin.slice(vin.length - 7));
    this.createAndOpenServiceCase(serviceCase, origin);
  }

  private openNewServiceCaseByDmsVehicle(dmsVehicle: DmsVehicle, origin: string): void {
    const serviceCase = ServiceCase.constructMinimalServiceCase(this.userService.userSubject.getValue());
    serviceCase.setDmsOrderNumber(dmsVehicle.orderNumber);
    serviceCase.setCustomerName(dmsVehicle.customerName);
    serviceCase.setDmsCustomerNumber(dmsVehicle.customerId);
    const vin = dmsVehicle.vin.toUpperCase();
    serviceCase.setVinLong(vin.length === 17 ? vin : null);
    serviceCase.setVinShort(vin.slice(vin.length - 7));
    serviceCase.setLicensePlate(dmsVehicle.licensePlate);
    this.createAndOpenServiceCase(serviceCase, origin);
  }

  private createAndOpenServiceCase(serviceCase: ServiceCase, origin: string): void {
    this.serviceCaseLoader.createServiceCase(serviceCase)
      .then((createdServiceCase: ServiceCase): void => {
        // Frontend timestamp can be manipulated. Therefore, we reset the default title of the service case according to
        // the actual timestamp set in the backend with respect to the user's locale.
        createdServiceCase.setTitle(formatDate(
          createdServiceCase.getCreatedAt(),
          'short',
          this.userService.userSubject.getValue().getLocale()
        ));
        this.vehicleLoader.loadVehicleInServiceCase(createdServiceCase)
          .then((mustSaveServiceCase: boolean): void => {
            if (mustSaveServiceCase || createdServiceCase.getSccId()) {
              this.serviceCaseUpdater.saveServiceCaseMetadata(createdServiceCase);
            }
            this.openServiceCase(createdServiceCase, true);
            this.analyticsService.postServiceCaseCrudEvent(
              AnalyticsAction.CREATE_IN_NEW_TAB,
              origin,
              createdServiceCase
            );
          });
      })
      .catch(reason => this.handleServiceCaseOpenError(reason));
  }

  // endregion create service cases

  // region load service cases

  openExistingServiceCaseById(externalId: string, origin: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.caseLoadingStarted.next();
      this.serviceCaseLoader.getServiceCaseById(externalId)
        .then(serviceCase => {
          this.vehicleLoader.loadVehicleInServiceCase(serviceCase)
            .then(mustSaveServiceCase => {
              if (mustSaveServiceCase) {
                this.serviceCaseUpdater.saveServiceCaseMetadata(serviceCase);
              }
              this.openServiceCase(serviceCase, true);
              this.analyticsService.postServiceCaseCrudEvent(AnalyticsAction.OPEN_IN_NEW_TAB, origin, serviceCase);
              resolve();
            });
        })
        .catch(reason => {
          this.handleServiceCaseOpenError(reason);
          reject(reason);
        });
    });
  }

  reloadServiceCaseById(externalId: string, setActive: boolean): Promise<void> {
    return new Promise(resolve => {
      this.serviceCaseLoader.getServiceCaseById(externalId)
        .then(serviceCase => {
          this.vehicleLoader.loadVehicleInServiceCase(serviceCase)
            .then(mustSaveServiceCase => {
              if (mustSaveServiceCase) {
                this.serviceCaseUpdater.saveServiceCaseMetadata(serviceCase);
              }
              this.openServiceCase(serviceCase, false);
            });
        })
        .catch(reason => {
          const serviceCase = ServiceCase.constructMinimalServiceCase(this.userService.userSubject.getValue());
          serviceCase.setExternalId(externalId);
          serviceCase.loadingError = reason;
          this.serviceCaseHolder.openServiceCaseOrSetActive(serviceCase, setActive);
        })
        .finally(resolve);
    });
  }

  selectAndOpenServiceCaseByVin(vin: string, origin: string): void {
    this.caseLoadingStarted.next();
    const user = this.userService.userSubject.getValue();
    const checkForExistingCases = !user.getNewCaseAfterVehicleIdentification();
    if (checkForExistingCases) {
      Promise
        .all([this.serviceCaseLoader.getServiceCasesByVin(vin), this.searchOrderViaDcom(vin)])
        .then(results => {
          const serviceCases = results[0];
          const dmsOrders = this.filterDmsOrdersByServiceCases(results[1].orders, serviceCases);
          if (serviceCases.length === 0 && dmsOrders.length === 0) {
            this.openNewServiceCaseByVin(vin, origin);
          } else {
            this.offerExistingServiceCaseToOpen(serviceCases, dmsOrders, vin, origin);
          }
        })
        .catch(reason => this.handleServiceCaseOpenError(reason));
    } else {
      this.openNewServiceCaseByVin(vin, origin);
    }
  }

  selectAndOpenServiceCaseByDmsVehicle(dmsVehicle: DmsVehicle, origin: string): void {
    this.caseLoadingStarted.next();
    const user = this.userService.userSubject.getValue();
    const checkForExistingCases = !user.getNewCaseAfterVehicleIdentification();
    if (checkForExistingCases) {
      this.serviceCaseLoader.getServiceCasesByVin(dmsVehicle.vin)
        .then(serviceCases => {
          serviceCases = this.filterServiceCasesByDmsOrder(serviceCases, dmsVehicle.orderNumber);
          if (serviceCases.length === 0) {
            this.openNewServiceCaseByDmsVehicle(dmsVehicle, origin);
          } else {
            this.offerExistingServiceCaseToOpenWithDmsVehicle(serviceCases, dmsVehicle, origin);
          }
        }).catch(reason => this.handleServiceCaseOpenError(reason));
    } else {
      this.openNewServiceCaseByDmsVehicle(dmsVehicle, origin);
    }
  }

  private offerExistingServiceCaseToOpen(serviceCases: ServiceCase[],
                                         dmsOrders: OrderInfo[],
                                         vin: string,
                                         origin: string): void {
    this.caseSelectionOverlay.show(serviceCases, dmsOrders)
      .then(serviceCase => {
        if (serviceCase?.isPersisted()) {
          this.openExistingServiceCaseById(serviceCase.getExternalId(), origin)
            .finally(() => {
              // do nothing
            });
        } else if (serviceCase) {
          // User has selected pre-existing DMS order, see DmsOrderForVinSelectionComponent.
          // This creates a new, not yet persisted service case with rudimentary data.
          this.createAndOpenServiceCase(serviceCase, origin);
        } else {
          this.openNewServiceCaseByVin(vin, origin);
        }
      })
      .catch(() => this.caseLoadingFinished.next());
  }

  private offerExistingServiceCaseToOpenWithDmsVehicle(serviceCases: ServiceCase[],
                                                       dmsVehicleSearchResult: DmsVehicle,
                                                       origin: string): void {
    this.caseSelectionOverlay.show(serviceCases, [])
      .then(serviceCase => {
        if (serviceCase) {
          this.openExistingServiceCaseById(serviceCase.getExternalId(), origin)
            .finally(() => {
              // do nothing
            });
        } else {
          this.openNewServiceCaseByDmsVehicle(dmsVehicleSearchResult, origin);
        }
      })
      .catch(() => this.caseLoadingFinished.next());
  }

  private filterServiceCasesByDmsOrder(serviceCases: ServiceCase[], orderNumber: string): ServiceCase[] {
    // filter for service cases that match selected dms order
    if (orderNumber) {
      serviceCases = serviceCases.filter(s => s.getDmsOrderNumber() === orderNumber);
    }

    return serviceCases;
  }

  private filterDmsOrdersByServiceCases(dmsOrders: OrderInfo[], serviceCases: ServiceCase[]): OrderInfo[] {
    // filter dms orders that do not match existing service cases
    return dmsOrders.filter(order => serviceCases
      .every(serviceCase => serviceCase.getDmsOrderNumber() !== order.serviceOrderNumber));
  }

  private searchOrderViaDcom(vin: string): Promise<OrderSearchResult> {
    if (this.dmsService.dmsSettings.transferMode === TransferMode.Dcom) {
      return new Promise(resolve => {
        this.dmsService
          .searchOrderViaDcom(undefined, vin, this.MAX_SEARCH_RESULTS)
          .then(result => resolve(result))
          .catch(() => {
            // if fails, resolve empty order list, so that vehicle identification does not fail
            console.warn(`[Webapp] [DMS] Failed to load existing DMS orders for ${vin}. No DMS orders will be shown.`);
            resolve(new OrderSearchResult([], this.MAX_SEARCH_RESULTS));
          });
      });
    } else {
      return Promise.resolve(new OrderSearchResult([], this.MAX_SEARCH_RESULTS));
    }
  }

  private subscribeToRequestToOpenServiceCaseByVin(): void {
    this.awpLib.subscribeToOpenServiceCaseByVin((vin: string, origin: ActorId) => {
      this.selectAndOpenServiceCaseByVin(vin, origin);
    });
  }

  restoreServiceCases(serviceCaseIds: string[], activeServiceCaseId: string): void {
    Promise
      .all(serviceCaseIds.map(externalId => new Promise<void>(resolve => {
        if (this.serviceCaseHolder.isServiceCaseOpen(externalId)) {
          // ignore already opened service cases
          resolve();
        } else {
          // If active service case could not been opened before, here is a retry. Even if that fails again, we will
          // have at least an active service case (with error message).
          const setActive = externalId === activeServiceCaseId;
          this.reloadServiceCaseById(externalId, setActive)
            .then(resolve);
        }
      })))
      .then(() => this.serviceCaseHolder.sortServiceCases((left, right) =>
        serviceCaseIds.indexOf(left.getExternalId()) - serviceCaseIds.indexOf(right.getExternalId())
      ));
  }

  // endregion load service cases

  // region open service cases

  private openServiceCase(serviceCase: ServiceCase, setActive: boolean): void {
    this.serviceCaseHolder.openServiceCaseOrSetActive(serviceCase, setActive);
    this.caseLoadingFinished.next();
    this.vehicleDataLoader.loadVehicleRelatedData(serviceCase)
      .then(mustSaveServiceCase => {
        if (mustSaveServiceCase) {
          this.serviceCaseUpdater.saveServiceCaseMetadata(serviceCase);
        }
        if (serviceCase.getExternalId() === this.serviceCaseHolder.getActiveCase()?.getExternalId()) {
          this.serviceCaseHolder.onActiveCaseChanged();
        }
      });
  }

  private handleServiceCaseOpenError(reason: string): void {
    this.awpLib.notifyError(reason);
    this.caseLoadingFinished.next();
  }

  // endregion open service cases

  // region update service cases

  private onTranslationChange() {
    this.serviceCaseHolder.serviceCases.forEach(serviceCase => {
      const setActive = serviceCase.getExternalId() === this.serviceCaseHolder.getActiveCase()?.getExternalId();
      this.reloadServiceCaseById(serviceCase.getExternalId(), setActive)
        .finally(() => {
          // do nothing
        });
    });
  }

  // endregion update service cases
}
