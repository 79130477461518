<div class="order-tiles">
  @for (order of orderList; track order) {
    <div class="order-tile">
      <div class="meta-data container-fluid" (click)="orderSelected(order)">
        <div class="row">
          <div class="col-6">
            <div class="text-label">{{ 'case.dmsOrderNumber' | translate }}</div>
            <div class="value bold" [title]="order.serviceOrderNumber">
              {{ order.serviceOrderNumber }}
            </div>
          </div>
          <div class="col-6">
            <div class="text-label">{{ 'case.dmsCustomerNumber' | translate }}</div>
            <div class="value" [title]="order.customer?.customerId?.dmsId">
              {{ order.customer?.customerId?.dmsId }}
            </div>
          </div>
        </div>
      </div>
    </div>
  }
</div>
